export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-tachometer',
    role: 'RO_DB'
  },
  {
    name: 'Risks Heatmap',
    url: '/view/report/heatmap',
    icon: 'fa fa-th-large',
    role: 'RO_DB'
  },
  {
    "name": "Configure Master Data",
    "icon": "fa fa-cogs",
    "role": 'RO_MD',
    "children": [
      {
        "name": "User Groups",
        "icon": "fa fa-empty",
        "url": "admin/md/groups"
      },
      {
        "name": "Regions",
        "icon": "fa fa-empty",
        "url": "admin/md/region"
      },
      {
        "name": "Users",
        "icon": "fa fa-empty",
        "url": "admin/md/users"
      },
      {
        "name": "Admins",
        "icon": "fa fa-empty",
        "url": "admin/md/admins"
      },
      {
        "name": "OTP",
        "icon": "fa fa-empty",
        "url": "admin/md/otp"
      },
      {
        "name": "Categories",
        "icon": "fa fa-empty",
        "url": "admin/md/categories"
      },
      {
        "name": "Sub Categories",
        "icon": "fa fa-empty",
        "url": "admin/md/subCategories"
      },
      {
        "name": "Risks",
        "icon": "fa fa-empty",
        "url": "admin/md/risks"
      },
      {
        "name": "Significance",
        "icon": "fa fa-empty",
        "url": "admin/md/significances"
      },
      /*{
        "name": "Likelihood",
        "icon": "fa fa-empty",
        "url": "admin/md/likelihoods"
      },*/
      {
        "name": "Vulnerability",
        "icon": "fa fa-empty",
        "url": "admin/md/vulnerabilitys"
      },
      {
        "name": "Scores",
        "icon": "fa fa-empty",
        "url": "admin/md/scores"
      }
    ]
  },
  {
    "name": "ERM Survey",
    "icon": "fa fa-check-circle-o",
    "role": 'RO_TD',
    "children": [
      // {
      //   name: 'Take Survey',
      //   url: 'create/survey/new',
      //   icon: 'fa fa-search',
      // },
      {	
        name: 'Take Survey',	
        url: 'create/survey/initiate/new',	
        icon: 'fa fa-pencil-square-o',	
        /*badge: {	
          text: 'New',	
          variant: 'info',	
        }	*/
      },
      {
        name: 'All Surveys',
        url: 'view/surveys/all',
        icon: 'fa fa-table',
      }
    ]
  },
  {
    "name": "Reports",
    "icon": "fa fa-file-text-o",
    "role": 'RO_TD',
    "children": [
      {
      name: 'Survey Status',
      url: 'view/report/users',
      icon: 'fa fa-users',
    },
    {
    name: 'Risks',
    url: 'view/report/risks',
    icon: 'fa fa-bolt',
  },
      /*{
        name: 'Risk Completeness',
        url: 'view/report/risk-completeness',
        icon: 'fa fa-table',
      },
      {
        name: 'Risk Identification',
        url: 'view/report/risk-identification',
        icon: 'fa fa-table',
      }*/
    ]
  }
];
