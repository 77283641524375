import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) {

  }

  getDashboardCounts(year:any) {
    return this.httpClient.get<any>(environment.apiUrl + '/getDashboardCounts/'+year);
  }

}
