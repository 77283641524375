import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserInfo } from '../models/user-info.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from '@progress/kendo-angular-notification';


@Injectable({
  providedIn: 'root'
})
export class CommonService{

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar,
    private notificationService: NotificationService) { }

  isAdmin(data: any): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.apiUrl + '/isAdmin', { params: data });
  }

  /** Format User Info from AD */
  formatUserInfo(userData: any) {
    let userInfo = new UserInfo();
    userInfo.displayName = userData.displayName;
    userInfo.givenName = userData.givenName;
    userInfo.jobTitle = userData.jobTitle;
    userInfo.mail = userData.mail;
    userInfo.mobilePhone = userData.mobilePhone;
    userInfo.officeLocation = userData.officeLocation;
    userInfo.surname = userData.surname;
    userInfo.userPrincipalName = userData.userPrincipalName;
    //  userInfo.businessPhones = userData.businessPhones[0];
    userInfo.globalId = userData.userPrincipalName.substring(0, userData.userPrincipalName.indexOf('@'));
    userInfo.profilePic = userData.profilePic;
    return userInfo;
  }

  /**  */
  openSnackBar(message: string, styleClass) {
    this.snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['ui-notification', styleClass]
    });
  }

  showNotification(message: any, type: any): void {
    this.notificationService.show({
      content: message,
      cssClass: 'button-notification prodash-snack',
      animation: { type: 'slide', duration: 200 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: type, icon: true },
      hideAfter: 2000
    });
  }

}
