import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './container/default-layout/default-layout.component';
import { ADResolverService } from './resolvers/adresolver.service';
import { MsalGuard } from './msal';
import { HomeComponent } from './views/home/home.component';
import { DashboardComponent } from './views/dashboard/dashboard/dashboard.component';
import { enableMSAL } from './config/msal.config';
import { LoginComponent } from './views/login/login.component';

const guards: any[] = enableMSAL ? [MsalGuard] : [];
const resolvers: any = enableMSAL ? {profile: ADResolverService} : {};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    canActivate: guards,
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: guards,
    resolve: resolvers,
    data: {
      title: 'Login'
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {
          title: 'Login'
        }
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          title: 'Home'
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module')
          .then(m => m.AdminModule)
      },
      {
        path: 'create',
        loadChildren: () => import('./views/create/create.module')
          .then(m => m.CreateModule)
      },
      {
        path: 'view',
        loadChildren: () => import('./views/view/view.module')
          .then(m => m.ViewModule)
      }
    ]
  }
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  //useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
