import { MsalGuardConfiguration } from '../../msal/msal.guard.config';
import { MSAL_GUARD_CONFIG } from '../../msal/constants';
import { ProfileService } from '../../services/profile.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MsalService } from '../../msal';
import { enableMSAL } from 'src/app/config/msal.config';
import { Globals } from 'src/app/globals/globals';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isBusy:boolean = false;
  userName:string = 'Anandraj Asokan';
  loginForm:FormGroup;
  showOTPInput:boolean = false;
  showMandatoryError:boolean = false;
  showOTPError:boolean = false;
  showLoginError:boolean = false;
  showSurveyCompletedError:boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private profileService: ProfileService,
    private loginService: LoginService,
    private globals: Globals,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('',[Validators.required,Validators.email]),
      otp: new FormControl('')
    });
    if(enableMSAL){
      this.authService.handleRedirectObservable().subscribe({
        next: (result) => {
          this.handleRedirectResponse(result);
        },
        error: (error) => {
          console.log('Error in handleRedirectObservable :  ' + error);
        }
      });
    }
  }

  authenticateUser(){ 
    //bypass the login - start
    /*this.globals.isAuthenticated = true;      
    this.globals.isAdmin = true;
    this.showLoginError = false;
    this.showOTPError = false;
    this.showMandatoryError = false;
    this.showOTPInput = false;
    this.showSurveyCompletedError = false;    
    this.globals.user = JSON.parse("{\"userId\":14,\"createdBy\":1,\"createdOn\":\"2022-05-24T03:24:53.670+00:00\",\"displayFlag\":\"Y\",\"displayName\":\"Joseph Raj\",\"email\":\"joseph.1.raj-ext@adient.com\",\"firstName\":\"Joseph\",\"globalId\":\"arajj4\",\"lastName\":\"Raj\",\"lastNotifiedOn\":\"2023-05-29T23:48:24.363+00:00\",\"modifiedBy\":1,\"modifiedOn\":\"2022-05-24T03:24:53.670+00:00\",\"zmdGroup\":{\"groupId\":2,\"createdBy\":1,\"createdOn\":\"2021-06-28T04:44:02.127+00:00\",\"displayFlag\":\"Y\",\"groupName\":\"Admin\",\"groupOrder\":0,\"modifiedBy\":1,\"modifiedOn\":\"2021-06-28T04:44:02.127+00:00\"}}");
    this.router.navigate(['/dashboard']);*/
    //bypass the login - end

    if(!this.showOTPInput){
      this.isBusy = true;
      if(null!=this.loginForm.get('email') && undefined!=this.loginForm.get('email') &&
      null!=this.loginForm.get('email').value && ''!=this.loginForm.get('email').value){
        this.loginService.generateOTP(this.loginForm.get('email').value).subscribe(data=>{
          if(data){
            this.showOTPError = false;
            this.showMandatoryError = false;
            this.showOTPInput = true;
            this.showLoginError = false;
          }else{
            this.showOTPError = false;
            this.showOTPInput = false;
            this.showMandatoryError = false;
            this.showLoginError = true;
            this.loginForm.reset();
          }            
          this.isBusy = false;
        },error=>{
          this.isBusy = false;
          this.showOTPError = true;
          this.showOTPInput = false;
          this.showMandatoryError = false;
          this.showLoginError = false;
          this.loginForm.reset();
        });
      }else{
        this.isBusy = false;
        this.showMandatoryError = true;
        this.showOTPError = false;
        this.showOTPInput = false;
        this.showLoginError = false;
      }
    }else{
      this.isBusy = true;
      if(null!=this.loginForm.get('email') && undefined!=this.loginForm.get('email') &&
      null!=this.loginForm.get('email').value && ''!=this.loginForm.get('email').value &&
      null!=this.loginForm.get('otp') && undefined!=this.loginForm.get('otp') &&
      null!=this.loginForm.get('otp').value && ''!=this.loginForm.get('otp').value){
        this.loginService.validateOTP(this.loginForm.get('email').value,
        this.loginForm.get('otp').value).subscribe(data=>{
          if(null!=data && undefined!=data && null!=data.user){
            this.loginForm.reset();
            this.globals.isAuthenticated = true;      
            this.globals.isAdmin = data.isAdmin;
            this.globals.user = data.user;
            this.showLoginError = false;
            this.showOTPError = false;
            this.showMandatoryError = false;
            this.showOTPInput = true;
            this.showSurveyCompletedError = false;
            if(this.globals.isAdmin){
              this.showSurveyCompletedError = false;
              this.router.navigate(['/dashboard']);
            }else if(null!=data.hasTakenSurvey && undefined!=data.hasTakenSurvey && data.hasTakenSurvey){
              //this.router.navigate(['/view/survey-already-completed']);
              this.showSurveyCompletedError = true; 
              this.globals.isAdmin = false;
              this.globals.user = null;
              this.globals.isAuthenticated = false;     
            }else{
              this.showSurveyCompletedError = false;
              this.router.navigate(['/create/survey/initiate/new']);
            }
          }else{            
            this.loginForm.reset();
            this.showLoginError = true;
            this.showOTPInput = false;
            this.showOTPError = false;
            this.showMandatoryError = false;
            this.showSurveyCompletedError = false;
          }
          this.isBusy = false;
        },error=>{
          this.loginForm.reset();
          this.showLoginError = true;
          this.showOTPInput = false;
          this.showOTPError = false;
          this.showMandatoryError = false;
          this.showSurveyCompletedError = false;
          this.isBusy = false;
        });
      }else{
        this.showMandatoryError = true;
        this.showLoginError = false;
        this.showOTPError = false;
        this.showOTPInput = true;
        this.showSurveyCompletedError = false;
        this.isBusy = false;
      }
    }
  }

  handleRedirectResponse(resp) {
    if (resp != null) {
      this.profileService.updateUsrLoginInfo(true);
    } else if (this.authService.getAllAccounts().length > 0) {
      this.profileService.updateUsrLoginInfo(true);
    } else {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest });
    }
  }

}
