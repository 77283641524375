import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AppConstants } from '../../app.constants';


@Injectable({
  providedIn: 'root'
})
export class SearchUserService {
  private _searchUrl: string = '';
  private _searchStr: string = "startswith(givenName, '::qrystr') or startswith(surname, '::qrystr') or startswith(displayName, '::qrystr') or startswith(userPrincipalName, '::qrystr')"

  private _replaceR = /::qrystr/gi;

  constructor(private _http: HttpClient, private _constants: AppConstants) { }

  search(queryString: string) {
    if (queryString === '') {
      return of({ value: [] });
    }
    this._searchUrl = this._constants.GRAPH_ENDPOINT + 'users?$filter=(' + this._searchStr.replace(this._replaceR, queryString) + ')';
    //console.log('graph query url: ', this._searchUrl);
    try {
      return this._http.get<any[]>(this._searchUrl);
    } catch (e) {
      return of({ value: [] });
    }
  }

}
