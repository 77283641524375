<div class="container-fluid">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 headerWrapper row">
        <div class="col-12">
            <h3 class="page-header"><span style="color: #bfd732"> π π π </span>Welcome {{userName}}!</h3>
            <hr>
        </div>
    </div>

    <div class="row mt-40">
        <div class="col-12" style="text-align: center;">
            <img class="img-fluid rounded m-auto" src="assets/img/survey.png">
        </div>
    </div>


    <div class="col-12 mt-20" style="text-align: center;">
        <a class="btn btn-adient" style="min-height: 0 !important;" [routerLink]="['/create/survey/initiate/new']"> <i class="fa fa-search btn-icon"></i>Take Survey</a>
        <a class="btn btn-adient" style="min-height: 0 !important;" [routerLink]="['/view/surveys/user']"><i class="fa fa-book btn-icon"></i>View My Surveys</a>
    </div>
</div>