import { UserInfo } from '../models/user-info.model';
import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmployeeSearchReqdData } from '../models/user-info.model';
import { AppConstants } from '../app.constants';
import { Globals } from '../globals/globals';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  loggedInUser = new UserInfo();

  @Output() getLoggedInUser: EventEmitter<UserInfo> = new EventEmitter();

  private _userInfo = new BehaviorSubject(new UserInfo());
  usrInfoSubject$ = this._userInfo.asObservable();

  private _isUsrLoggedIn = new BehaviorSubject(false);
  isUsrLoggedIn$ = this._isUsrLoggedIn.asObservable();

  constructor(private http: HttpClient, private constants: AppConstants, private commonService: CommonService,
    private globals: Globals) { }

  updateUserInfo(userinfo: any) {
    this._userInfo.next(userinfo);
  }

  updateUsrLoginInfo(isloggedin: boolean) {
    this._isUsrLoggedIn.next(isloggedin);
  }

  /**  */
  getProfileInfo() {
    return this.http.get(this.constants.GRAPH_ENDPOINT + 'me');
  }

  /**  */
  getProfile() {
    this.http.get(this.constants.GRAPH_ENDPOINT + 'me')
      .subscribe(profile => {
        this.updateUserInfo(this.commonService.formatUserInfo(profile));
        //  this.getProfileImage();
      });
  }

  /** Search User based on Search parameters */
  searchUser(reqdData: EmployeeSearchReqdData) {
    let url = '';
    if (reqdData.firstName !== '') {
      url = url + 'startswith(givenName, \'' + reqdData.firstName + '\')';
    }
    if (reqdData.lastName !== '') {
      if (url !== '') {
        url = url + (' and ');
      }
      url = url + 'startswith(surname, \'' + reqdData.lastName + '\')';
    }
    if (reqdData.globalId !== '') {
      if (url !== '') {
        url = url + (' and ');
      }
      url = url + 'startswith(userPrincipalName, \'' + reqdData.globalId + '\')';
    }
    return this.http.get<any[]>(this.constants.GRAPH_ENDPOINT + 'users?$filter=(' + url + ')');
  }


  /**  */
  getProfileImage() {
    const url = this.constants.GRAPH_ENDPOINT + 'me/photos/48x48/$value';
    this.getImage(url).subscribe(data => {
      this.createImageFromBlob(data);
    }, error => {
      console.log(error);
    });
  }

  /**  */
  getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  /** */
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      const loggedInUserPhoto = reader.result;
      this.loggedInUser.profilePic = loggedInUserPhoto;
      this.getLoggedInUser.emit(this.loggedInUser);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
