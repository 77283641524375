<style>
  .example-container {
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .make-absolute {
    position: absolute;
  }

  .example-is-mobile .example-toolbar {
    position: fixed;
    /* Make sure the toolbar will stay on top of the content as it scrolls past. */
    z-index: 2;
  }

  h1.example-app-name {
    margin-left: 8px;
  }

  .example-sidenav-container {
    /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
    flex: 1;
  }

  .example-is-mobile .example-sidenav-container {
    /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
    flex: 1 0 auto;
  }

  .tutorial-video {
    padding: 2em !important;
  }

  .tutorial-video:focus {
    outline: none;
  }

  .active {
    background-color: #ddd !important;
  }
</style>
<div class="example-container" [ngClass]="{'make-absolute':maximize}" [class.example-is-mobile]="mobileQuery.matches"
  style="height: 100vh;">
  <mat-toolbar color="primary" class="example-toolbar">
    <button mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="example-app-name">Smart Survey Tutorials</h1>
    <div style="margin-left: auto;margin-right: 0;">
      <button *ngIf="!maximize" mat-icon-button (click)="maximize=true;">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button *ngIf="maximize" mat-icon-button (click)="maximize=false;">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button mat-icon-button (click)=" onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>
