import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient) {

  }

  saveSurvey(survey: any) {
    return this.httpClient.post<any>(environment.apiUrl + '/saveSurvey', survey);
  }

  getAllSurveys(year:any) {
    return this.httpClient.get<any>(environment.apiUrl + '/getAllSurveys/'+ year);
  }

  getRecentSurveys(year:any) {
    return this.httpClient.get<any>(environment.apiUrl + '/getRecentSurveys/'+ year);
  }

  getSurveyById(id: number) {
    return this.httpClient.get<any>(environment.apiUrl + '/getSurveyById/' + id);
  }

  getUsersForReports(selctedYear: any) {
    return this.httpClient.get<any>(environment.apiUrl + '/getUsersForReports/'+selctedYear);
  }

  sendMail(notifyAllUsers: boolean, mail: any) {
    return this.httpClient.post<any>(environment.apiUrl + '/sendMail/' + notifyAllUsers, mail);
  }

  getRisksForReports(type: any, param: number[]) {
    return this.httpClient.post<any>(environment.apiUrl + '/getRisksForDashboard/' + type , param);
  }

  /*getRisksForDashboardAsList(type: any, param: number) {
    return this.httpClient.get<any>(environment.apiUrl + '/getRisksForDashboardAsList/' + type + '/' + param);
  }*/

  getRisksForDashboardAsList(type: any, param: number[], year:number) {
    return this.httpClient.post<any>(environment.apiUrl + '/getRisksForDashboardAsList/' + type + "/" + year , param);
  }
}
