<app-overlay-spinner [hidden]="!isBusy"></app-overlay-spinner>
<ng-container *ngIf="!isBusy">
  <app-header class="" [fixed]="true"
    [navbarBrandFull]="{src: 'assets/img/brand/adient_logo_onwhite.png', width: 125, height: 65, alt: 'Adient Logo'}"
    [navbarBrandMinimized]="{src: 'assets/img/brand/adient_logo_onwhite.png', width: 125, height: 65, alt: 'Adient Logo'}"
    [asideMenuToggler]="false" [mobileAsideMenuToggler]='false' [sidebarToggler]="globals.isAdmin?'lg':''"
    style="background-color: #ffffff !important">
    <a class="navbar-brand adient-brand" href="javascript:void(0);" [ngStyle]="{'margin-left': (globals.isAdmin) ? '0px' : '30px'}">
      &nbsp;<span class="brand-text-dark" style="color: #00465B">{{appConfig.title1}}</span>
      <span class="brand-text-light">{{appConfig.title2}}</span>
    </a>
    <a class="navbar-brand adient-brand header-brand" href="javascript:void(0);" style="color: #00465B" *ngIf="appConfig.applicationName && appConfig.applicationName !== ''">
      {{appConfig.applicationName}}
    </a>
    <ul class="nav nav-menu navbar-nav ml-auto">
      <li class="nav-item dropdown" dropdown placement="bottom right" *ngIf="appConfig.showCreate  && appConfig.createOptions && appConfig.createOptions.length>0">
        <button class="btn menu-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" dropdownToggle (click)="false">
          UI
        </button>
        <div class="dropdown-menu dropdown-menu-right" style="left: auto; right: 0px; min-width: 150px !important;"
          *dropdownMenu aria-labelledby="simple-dropdown">
          <a class="dropdown-item" *ngFor="let option of appConfig.createOptions" [routerLink]="option.link">
            <i class="nav-submenu-icon" *ngIf="option.icon" [ngClass]="option.icon"></i>{{option.title}}
          </a>
        </div>
      </li>
      <!--Help Menu-->
      <li class="nav-item dropdown" dropdown placement="bottom right" style="margin-left: 20px;" *ngIf="appConfig.showHelp">
        <button class="btn menu-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" dropdownToggle (click)="false">
          Help
        </button>
        <div class="dropdown-menu dropdown-menu-right" style="left: auto; right: 0px; min-width: 200px !important;"
          *dropdownMenu aria-labelledby="simple-dropdown">
          <a class="dropdown-item" (click)="openHelp()" href="javascript:void(0);">
            <i class="fa fa-life-ring nav-submenu-icon"></i>Support
          </a>
          <a class="dropdown-item" (click)="openHelpTutorials()" href="javascript:void(0);">
            <i class="fa fa-question-circle-o nav-submenu-icon"></i>Tutorials
          </a>
        </div>
      </li>
      <!--Settings Menu-->
      <li class="nav-item dropdown" dropdown placement="bottom right" *ngIf="appConfig.showSettings && globals.isAuthenticated">
        <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
          dropdownToggle (click)="false">
          <i class="material-icons">settings</i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" style="left: auto; right: 0px; min-width: 300px !important;"
          *dropdownMenu aria-labelledby="simple-dropdown">
          <div class="dropdown-header-main text-center"><strong>{{loggedInUser?.displayName}}</strong></div>
          <div class="dropdown-header text-center"><strong>Settings</strong></div>
          <a class="dropdown-item" (click)="logout()">
            <i class="fa fa-sign-out nav-submenu-icon"></i>Logout
          </a>
        </div>
      </li>
    </ul>
  </app-header>

  <div class="app-body" [style.marginTop]="navigationClosed ? '0' : ''">
    <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
      (minimizedChange)="toggleMinimize($event)" *ngIf="globals.isAdmin">
      <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
      <app-sidebar-minimizer></app-sidebar-minimizer>
    </app-sidebar>

    <!-- Main content -->
    <main class="main" style="background-color: #ffffff">
      <div class="container-fluid" style="margin-top: 10px;margin-bottom: 70px;">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
  <app-footer>
    <!-- <div class="ml-auto" style="text-align: right;">
      <span style="font-weight: bold !important;font-size: 14px;margin-right: 5%;
      color: #23282c;">Built on</span><br/>
      <a href="https://noaharkappstore-web-dev.azurewebsites.net/about/smartapps.html" target="_blank">
        <img src="assets/img/smartapps-dark.png" alt="SmartApps" style="height:30%;width:15%"/>
      </a>
    </div> -->
</app-footer>
</ng-container>