<app-overlay-spinner [hidden]="!isBusy"></app-overlay-spinner>
<ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div class="col-12 mt-30" *ngIf="!usedAsDirective">
    <h3 class="page-header"><span style="color: #bfd732"> π π π </span>{{title}}</h3>
</div>
<div class="col-12 mt-30" kendoTooltip
showOn="none"
[tooltipTemplate]="template"
filter=".k-grid td"
(mouseover)="showTooltip($event)">
    <kendo-grid [kendoGridBinding]="gridData" [pageSize]="gridState.take" [pageable]="true" [sortable]="true"
        filterable="menu" [filter]="gridState.filter" [pageable]="true" [resizable]="true" [height]="usedAsDirective?300:'100%'"
        [columnMenu]="true" class="mat-elevation-z8">
        <ng-template kendoGridToolbarTemplate position="top" *ngIf="!usedAsDirective">            
            <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button> &nbsp;
            <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button> &nbsp;
            <mat-form-field appearance="outline" class="mt-20">
                <mat-label>Select Year</mat-label>
                <mat-select [(ngModel)]="selectedYear" name="selectedYear" (selectionChange)="yearSelect()">
                    <mat-option *ngFor="let year of yearList" [value]="year">{{year}}</mat-option>                        
                </mat-select>
            </mat-form-field>
            <!--Search Box-->
            <div
                class="input-group mb-3 mt-2 search-master col-xs-10 col-sm-10 col-md-4 col-lg-2 col-xl-2 col-xxl-2 ml-auto">
                <div class="bg-light rounded rounded-pill shadow-sm col-12">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button id="search-btn" type="submit" class="btn btn-link text-adient-light paddingzero"><i
                                    class="fa fa-search"></i></button>
                        </div>
                        <input type="search" matInput (keyup)="searchGrid($event)"
                            placeholder="Search" aria-describedby="search-btn" class="form-control border-0 bg-light search-input"
                            style="font-size: 16px;">
                    </div>
                </div>
            </div>
            <!--Search Box-->
        </ng-template>
        <ng-container *ngFor="let col of columns">
            <kendo-grid-column media="(min-width: {{col.minWidth}})" [field]="col.field" [title]="col.title"
                 [width]="col.width" [filter]="col.type">
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="col.field === 'surveyId'">
                    <a href="#" routerLink="/view/survey/{{dataItem['surveyId']}}">
                        <span class="launch-id-style" [class]="col.class">
                            {{dataItem.surveyId}}
                        </span>
                    </a>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem *ngIf="col.field !== 'surveyId'">
                    <span [class]="col.class" [attr.data-val]="((null!=dataItem[col.field])?dataItem[col.field]:'')">
                        <ng-container *ngIf="col.type!='date'">
                            {{(null!=dataItem[col.field])?dataItem[col.field]:''}}
                        </ng-container>
                        <ng-container *ngIf="col.type=='date'">
                            {{(null!=dataItem[col.field])?(dataItem[col.field] | date):''}}
                        </ng-container>
                    </span>
            </ng-template>
            </kendo-grid-column>
        </ng-container>

        <kendo-grid-excel fileName="Surveys.xlsx" [fetchData]="allData"></kendo-grid-excel>

        <kendo-grid-pdf fileName="Surveys.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
            [landscape]="true">
            <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
            <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
                <div class="page-template">
                    <div class="header">
                        <div style="float: right">
                            Page {{ pageNum }} of {{ totalPages }}
                        </div>
                    </div>
                    <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
                </div>
            </ng-template>
        </kendo-grid-pdf>
    </kendo-grid>
</div>