import { ActivatedRoute } from '@angular/router';
import { inspectionList } from '../../../mock/data';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompositeFilterDescriptor, orderBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DataService } from '../../data.services';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from "@progress/kendo-data-query";

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.css']
})
export class InspectionListComponent implements OnInit {

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  title = 'Surveys';

  public isBusy: boolean = false;

  @Input() usedAsDirective: boolean;

  @Input() paramType: string = null;

  public defaultsort: SortDescriptor[] = [{
    field: 'submittedOn',
    dir: 'asc'
  }];

  public gridState: State = {
    skip: 0,
    take: 10,
    sort: this.defaultsort,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public total_records;
  public selectedYear: any;
  public yearList: any[] = [];

  public dashboardColumns: any[] = [
    { field: 'surveyId', title: 'ID', locked: true, width: "100", type: 'numeric', class: 'hyperlink', minWidth: '100px' },
    { field: 'submittedBy', title: 'Submitted By', locked: false, width: "200", type: 'text', minWidth: '400px' },
    { field: 'region', title: 'Region', locked: false, width: "200", type: 'text', minWidth: '400px' },
    { field: 'submittedOn', title: 'Submitted Date', locked: false, width: "120", type: 'date', minWidth: '100px' }
  ];

  public allColumns: any[] = [
    { field: 'surveyId', title: 'ID', locked: true, width: "100", type: 'numeric', class: 'hyperlink', minWidth: '100px' },
    { field: 'submittedBy', title: 'Submitted By', locked: false, width: "200", type: 'text', minWidth: '400px' },
    { field: 'region', title: 'Region', locked: false, width: "200", type: 'text', minWidth: '400px' },
    { field: 'submittedOn', title: 'Submitted Date', locked: false, width: "120", type: 'date', minWidth: '100px' }
  ];

  public columns: any[] = this.dashboardColumns;

  public gridView: GridDataResult;
  public gridData: any[] = inspectionList;

  public clearFilters() {
    this.gridState.filter = {
      logic: 'and',
      filters: []
    };
    this.gridState.sort = this.defaultsort;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.loadGridData();
  }

  private loadGridData(): void {
    this.gridView = {
      data: orderBy(this.gridData, this.defaultsort),
      total: this.gridData.length
    };
  }

  searchGrid(event) {
    let val = event.target.value;
    let filter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: []
    }
    if (val && val !== '') {
      this.columns.forEach(col => {
        let filterCondition = {
          field: col.field,
          operator: 'contains',
          value: val
        };
        filter.filters.push(filterCondition);
      });
    }
    this.gridState.filter = filter;
    this.loadGridData();
  }


  constructor(public dialog: MatDialog, private _activatedRoute: ActivatedRoute,
    private dataService: DataService) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit() {
    this.selectedYear = new Date().getFullYear();
    for (let year = 2022; year <= new Date().getFullYear(); year++) {
      this.yearList.push(year);
    }
    this._activatedRoute.paramMap.subscribe(params => {
      if (params.get('type')) {
        this.usedAsDirective = false;
        this.columns = this.allColumns;
        this.paramType = params.get('type');
      }
    });
    this.getRecentSurvey();
  }

  getRecentSurvey() {
    switch (this.paramType) {
      case 'all': {
        this.isBusy = true;
        this.dataService.getAllSurveys(this.selectedYear).subscribe(res => {
          this.gridData = res;
          this.total_records = this.gridData.length;
          if(this.gridData.length > 0){
            this.gridData.forEach(row => {
              row['submittedOn'] = new Date(row.submittedOn).toLocaleDateString("en-us");
            });            
          }
          this.title = 'All Surveys';
          this.isBusy = false;
        }), error => {
          this.gridData = [];
          this.total_records = 0;
          this.isBusy = false;
        };
        break;
      }
      case 'recent': {
        this.isBusy = true;
        this.dataService.getRecentSurveys(this.selectedYear).subscribe(res => {
          this.gridData = res;
          this.total_records = this.gridData.length;
          this.title = 'Recent Surveys';
          this.isBusy = false;
        }), error => {
          this.gridData = [];
          this.total_records = 0;
          this.isBusy = false;
        };
        break;
      }
      default: {
        this.gridData = inspectionList;
        this.total_records = this.gridData.length;
        break;
      }
    }
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }


  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
        group: [],
        sort: [{ field: "surveyId", dir: "asc" }],
      }).data,
      group: []
    };

    return result;
  }

  yearSelect() {    
    this.getRecentSurvey();
  }
}
