
export class UserInfo {
  public globalId: string;
  public businessPhones: string[];
  public displayName: string;
  public givenName: string;
  public jobTitle: string;
  public mail: any;
  public mobilePhone: string;
  public officeLocation: any;
  public preferredLanguage: string;
  public surname: string;
  public userPrincipalName: string;
  public profilePic: any;
}

export class EmployeeSearchReqdData {
  public firstName: string;
  public lastName: string;
  public globalId: string;
  public emailId: string;
  public phoneNumber: any;
}


export class ZmdAppUser{
  public appUserId?: number;
  public globalId: string;
  public firstName: string;
  public lastName: string;
  public displayName: string;
  public email: string;
  public deleteFlg?: string;
  public displayFlg?: string;
  public createdBy?: string;
  public createdOn?: Date;
  public modifiedBy?: string;
  public modifiedOn?: Date;
}
