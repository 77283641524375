export const applicationconfig = {
  title1: 'ERM',
  title2: 'Survey',
  applicationName: null,
  showHelp:false,
  showSettings:true,
  showCreate:false,
  createOptions:[
    {
      title:"UI 1",
      link:"/create/survey/new",
      icon:"fa fa-server"
    },    
    {
      title:"UI 2",
      link:"/create/survey2/new",
      icon:"fa fa-server"
    }, 
    {
      title:"UI 3",
      link:"/create/survey5/new",
      icon:"fa fa-server"
    },    
    {
      title:"UI 4",
      link:"/create/survey3/new",
      icon:"fa fa-server"
    },    
    {
      title:"UI 5",
      link:"/create/survey4/new",
      icon:"fa fa-server"
    }
  ]
};
