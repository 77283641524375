<app-overlay-spinner [hidden]="!isBusy"></app-overlay-spinner>
<!--<app-page-message [messageType]="'comingsoon'"></app-page-message>-->
<div class="row">
    <div class="col-12">
        <div class="k-card mat-elevation-z8">
            <div class="k-card-body">
                <div class="row">
                    <div class="col-12 col-lg-6 col-xl text-active active-issues">
                        <span class="comp-label">
                            <div class="issues-count">{{surveysCount}}</div>
                            <div class="issues-label">Surveys</div>
                        </span>
                        <kendo-sparkline style="height: 80px; width: 100%;">
                            <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
                            <kendo-chart-series-defaults type="column" [stack]="true" [gap]="0.5" [overlay]="false">
                            </kendo-chart-series-defaults>
                            <kendo-chart-series>
                                <kendo-chart-series-item type="area" [line]="{style:'smooth'}" [color]="'#ffc107'"
                                    [data]="chartdata">
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item [majorTicks]="{visible: false}"
                                    [labels]="{step: 4, skip: 2, font: '10px sans-serif'}"
                                    [majorGridLines]="{visible: false}" [line]="{visible: false}">
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
                            <kendo-chart-value-axis>
                                <kendo-chart-value-axis-item [visible]="false" [majorGridLines]="{visible: false}">
                                </kendo-chart-value-axis-item>
                            </kendo-chart-value-axis>
                        </kendo-sparkline>
                    </div>

                    <div class="col-12 col-lg-6 col-xl pb-4 text-success closed-issues">
                        <span class="comp-label">
                            <div class="issues-count">{{usersCount}}</div>
                            <div class="issues-label">Total Users</div>
                        </span>
                        <kendo-sparkline style="height: 80px; width: 100%;">
                            <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
                            <kendo-chart-series-defaults type="column" [stack]="true" [gap]="0.5" [overlay]="false">
                            </kendo-chart-series-defaults>
                            <kendo-chart-series>
                                <kendo-chart-series-item type="area" [line]="{style:'smooth'}" [color]="'#cade54'"
                                    [data]="chartdata">
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item [majorTicks]="{visible: false}"
                                    [labels]="{step: 4, skip: 2, font: '10px sans-serif'}"
                                    [majorGridLines]="{visible: false}" [line]="{visible: false}">
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
                            <kendo-chart-value-axis>
                                <kendo-chart-value-axis-item [visible]="false" [majorGridLines]="{visible: false}">
                                </kendo-chart-value-axis-item>
                            </kendo-chart-value-axis>
                        </kendo-sparkline>
                    </div>

                    <div class="col-12 col-lg-6 col-xl pb-4 text-success closed-issues">
                        <span class="comp-label">
                            <div class="issues-count">{{usersYetToTakeSurveyCount}}</div>
                            <div class="issues-label">Users Yet To Take The Survey</div>
                        </span>
                        <kendo-sparkline style="height: 80px; width: 100%;">
                            <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
                            <kendo-chart-series-defaults type="column" [stack]="true" [gap]="0.5" [overlay]="false">
                            </kendo-chart-series-defaults>
                            <kendo-chart-series>
                                <kendo-chart-series-item type="area" [line]="{style:'smooth'}" [color]="'#e91e63'"
                                    [data]="chartdata">
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item [majorTicks]="{visible: false}"
                                    [labels]="{step: 4, skip: 2, font: '10px sans-serif'}"
                                    [majorGridLines]="{visible: false}" [line]="{visible: false}">
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
                            <kendo-chart-value-axis>
                                <kendo-chart-value-axis-item [visible]="false" [majorGridLines]="{visible: false}">
                                </kendo-chart-value-axis-item>
                            </kendo-chart-value-axis>
                        </kendo-sparkline>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-xl text-active active-issues">
                        <div class="comp-label">
                            <div class="issues-count">{{risksCount}}</div>
                            <div class="issues-label">Risks</div>
                        </div>
                        <kendo-sparkline style="height: 80px; width: 100%">
                            <kendo-chart-tooltip format="{0}%"></kendo-chart-tooltip>
                            <kendo-chart-series-defaults type="column" [stack]="true" [gap]="0.5" [overlay]="false">
                            </kendo-chart-series-defaults>
                            <kendo-chart-series>
                                <kendo-chart-series-item type="area" [line]="{style:'smooth'}" [color]="'#00465B'"
                                    [data]="chartdata">
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item [majorTicks]="{visible: false}"
                                    [labels]="{step: 4, skip: 2, font: '10px sans-serif'}"
                                    [majorGridLines]="{visible: false}" [line]="{visible: false}">
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
                            <kendo-chart-value-axis>
                                <kendo-chart-value-axis-item [visible]="false" [majorGridLines]="{visible: false}">
                                </kendo-chart-value-axis-item>
                            </kendo-chart-value-axis>
                        </kendo-sparkline>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" style="margin-top: 30px;">
    <div class="col-xl-12 col-lg-12">
        <div class="k-card adientblueborder">
            <h2 class="k-card-header">Top 10 Risks</h2>            
            <div class="k-card-body" *ngIf="!top10Risks">
                Loading data...
            </div>
            <div class="k-card-body" *ngIf="top10Risks">
                <kendo-chart class="chartheight" style="height: 500px;">
                    <kendo-chart-series>
                        <kendo-chart-series-item type="column" [data]="top10Risks" field="score"
                            categoryField="riskName" [color]="'#00465B'" >
                        </kendo-chart-series-item>
                    </kendo-chart-series>

                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item
                          [labels]="seriesLabels">
                        </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                </kendo-chart>
            </div>
        </div>
    </div>
</div>
<div class="row" style="margin-top: 30px;margin-bottom: 50px;">
    <div class="col-12">
        <div class="k-card adientblueborder">
            <h2 class="k-card-header">Recent Surveys</h2>
            <div class="k-card-body">
                <app-inspection-list [usedAsDirective]="'true'" [paramType]="'recent'"></app-inspection-list>
            </div>
        </div>
    </div>
    <!--<div class="col-xl-3 col-lg-6">
        <div class="k-card adientblueborder full-height">
            <h2 class="k-card-header">Surveys By Month</h2>
            <div class="k-card-body height-1">
                <kendo-chart class="chartheight full-height">
                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item
                            [categories]="['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']"
                            [title]="{ text: 'Months' }">
                        </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                        <kendo-chart-series-item type="line" [data]="[5, 4, 10, 2, 1, 6, 7, 2, 5, 4, 3, 7]">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
    </div>-->
    <!--<div class="col-xl-3 col-lg-6">
        <div class="k-card adientblueborder full-height">
            <h2 class="k-card-header">Risk Distribution By Category</h2>
            <div class="k-card-body">
                <kendo-chart class="chartheight full-height">
                    <kendo-chart-legend position="bottom"></kendo-chart-legend>
                    <kendo-chart-series>
                        <kendo-chart-series-item type="pie" [data]="risksByCategories" field="value" [autoFit]="true"
                            categoryField="category" [labels]="{ visible: true, content: labelContent }">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
    </div>-->
</div>