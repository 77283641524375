<div class="container login-container">
    <div class="row">
        <div class="col-lg-3 col-md-2"></div>
        <div class="col-lg-6 col-md-8 login-box">
            <div class="col-lg-12 login-key">
                <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <div class="col-lg-12 login-title">
                User Login
            </div>

            <div class="col-lg-12 login-form">
                <div class="col-lg-12 login-form">
                    <form [formGroup]="loginForm">
                        <div class="form-group">
                            <label class="form-control-label">Email ID</label>
                            <input type="email" class="form-control" formControlName="email" name="email">
                        </div>
                        <div class="form-group" *ngIf="showOTPInput">
                            <label class="form-control-label">Access Code</label>
                            <input type="text" class="form-control" formControlName="otp" name="otp">
                        </div>

                        <div class="col-lg-12 loginbttm row">
                            <div class="col-lg-12 login-btm login-button" style="text-align: right;">
                                <button type="submit" class="btn btn-outline-primary" [disabled]="loginForm?.invalid"
                                    (click)="authenticateUser()">{{showOTPInput?'LOGIN':'REQUEST ACCESS CODE'}}</button>
                            </div>
                            <div class="col-lg-12 login-btm login-text">
                                <div class="loader text-center" *ngIf="isBusy"></div>
                                <h6 class="boldwhite" *ngIf="showOTPInput && !showSurveyCompletedError">An access code has been sent to you email. Please enter the same to proceed.</h6>
                                <h6 class="boldwhite" *ngIf="showSurveyCompletedError">Our records show that you have already taken the Survey. Please contact admin(Tammi S Dukes - tammi.dukes@adient.com) if you want to retake.</h6>
                                <h6 class="boldred" *ngIf="showMandatoryError">Please fill in required fields.</h6>
                                <h6 class="boldred" *ngIf="showOTPError">OTP request failed. Please try again or contact the
                                    admin(Tammi S Dukes - tammi.dukes@adient.com).</h6>
                                <h6 class="boldred" *ngIf="showLoginError">You are not authorized to access this application. Please contact admin(Tammi S Dukes - tammi.dukes@adient.com) for more details.
                                </h6>
                                <h6 style="color: white;">
                                    ERM Survey is compatible only with the following browsers: <br/>
                                    <strong>Google Chrome and Microsoft Edge </strong>
                                </h6>
                            </div>
                        </div>
                    </form>

                    <div class="col-12 text-center">                       
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>