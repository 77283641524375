import { Component, OnChanges, OnInit, NgZone, OnDestroy, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Globals } from '../../views/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { applicationconfig } from '../../config/application.config';
import { DisplayColumn } from 'src/app/models/common.model';
import { Globals } from 'src/app/globals/globals';
import { UserInfo } from 'src/app/models/user-info.model';
import { navItems } from 'src/app/nav';


declare var $: any;

@Component({
  selector: 'app-dashboard',
  styleUrls: ['./default-layout.component.scss'],
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent implements OnInit {

  appConfig = applicationconfig;
  loggedInUser = new UserInfo();
  isBusy = false;

  public navItems:any[] = [];
  // To close the Navigation layouts
  public navigationClosed: boolean;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;

  constructor(
    public ngZone: NgZone,
    public globals: Globals,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.changes = new MutationObserver(() => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(this.element as Element, {
      attributes: true
    });

  }

  ngOnInit() {
    this.loggedInUser = this.globals.user;
    this.globals.isAuthenticated = false;
    this.globals.isAdmin = false;
    this.router.navigate(['/login']);
    this.navItems = navItems;
  }

  /** Open Office display **/
  toggleOfficeDisplay() {
    this.navigationClosed = !this.navigationClosed;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  /** */
  openHelp() {
    const dialogRef = this.dialog.open(HelpDialog, {
      width: '60%',
      height: '80%',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  /** */
  openHelpTutorials() {
    const dialogRef = this.dialog.open(TutorialsDialog, {
      autoFocus: false,
      panelClass: 'tutorials-dialog',
      width: '1100px',
      height: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  /**  */
  getLoggedInUser(loggedInUser: UserInfo) {
    this.loggedInUser.profilePic = loggedInUser.profilePic;
    // this.globals.LoggedInUser = loggedInUser;
  }

  public logout = function () {
    this.authService.logout();
  };
}


/**
 * Component to Help Dialog
 */
@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.html',
})
export class HelpDialog implements OnInit {

  selected = 0;
  createTicketForm: FormGroup;

  /*ticketsData: any[] = [
    { "ticketNumber": "INC123456", "priority": "Medium", "status": "Resolved", "assignedTo": "Anandraj Asokan", "created": "08/06/2020", "resolved": "08/07/2020" },
    { "ticketNumber": "SCTASK123456", "priority": "Low", "status": "Assigned", "assignedTo": "Anandraj Asokan", "created": "08/10/2020", "resolved": null },
  ];*/

  ticketsData: any[] = [];

  displayedColumns: string[] = ['ticketNumber', 'priority', 'status', 'assignedTo', 'created', 'resolved'];

  displayedColumnObjects: DisplayColumn[] = [
    { ColumnCode: 'ticketNumber', ColumnName: 'Ticket#' },
    { ColumnCode: 'priority', ColumnName: 'Prioirity' },
    { ColumnCode: 'status', ColumnName: 'Status' },
    { ColumnCode: 'assignedTo', ColumnName: 'Assigned To' },
    { ColumnCode: 'created', ColumnName: 'Created Date' },
    { ColumnCode: 'resolved', ColumnName: 'Resolved Date' },
  ];

  ticketsDataSource;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<HelpDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {
    this.createTicketForm = new FormGroup({
      type: new FormControl(),
      priority: new FormControl(),
      description: new FormControl(),
    });

    this.ticketsDataSource = new MatTableDataSource(this.ticketsData);
    this.ticketsDataSource.sort = this.sort;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createTicket() {

  }

}

/**
 * Component for Tutorial Dialog
 */
@Component({
  selector: 'app-tutorials-dialog',
  templateUrl: './tutorials-dialog.html',
})
export class TutorialsDialog implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  selectedTutorial: number = null;

  showGetStarted = true;

  tutorials: any[] = [
    { tutorialId: 1, tutorialName: 'About ERM Survey', tutorialLink: '', isVideo: true }
  ];

  maximize = false;

  constructor(
    public dialogRef: MatDialogRef<TutorialsDialog>, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher,
    public sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getTutorialURL(tutorial: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(tutorial.tutorialLink);
  }

  showTutorial(tutorialId: number): boolean {
    if (this.selectedTutorial == tutorialId) {
      this.showGetStarted = false;
      return true;
    } else {
      this.showGetStarted = true;
      return false;
    }
  }

  startTutorial() {
    this.showGetStarted = false;
    this.selectedTutorial = 1;
  }
}
