import { AfterViewInit, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { LegendLabelsContentArgs, SeriesLabels } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { DataService } from '../../data.services';
import { DashboardService } from './dashboard.services';

@Component({
  selector: 'app-home',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit,AfterViewInit {
  isBusy = true;
  title = 'Payments';
  canvas: any;
  ctx: any;

  surveysCount:number = 0;
  usersCount:number = 0;
  usersYetToTakeSurveyCount:number = 0;
  risksCount:number = 0;


  public chartdata: any[] = [
    936, 1600, 1025, 1500, 400, 2000, 1300, 1800, 1010, 1007
  ];

  bulletData = [{ target: 70, current: Math.round(0.4 * 100) }];

  public npchartSeries: any[] = [{ data: [1, 2, 3, 5] }];
  public npchartTitle: any = { text: 'Number of Programs' };
  public risksByCategories: any[] = [
    { category: 'STRATEGIC', value: 31 },
    { category: 'OPERATIONAL', value: 34 },
    { category: 'FINANCIAL', value: 19 },
    { category: 'COMPLIANCE', value: 16 }
  ];

  public top10RiskLabels:any[] = [];//['Competition', 'Cost Optimization', 'Data Protection & Privacy', 'Quality Management', 'Image and Branding','Business Continuity', 'Cash Management', 'Pricing', 'Master Planning & Scheduling', 'Capital Allocation'];

  public top10RiskValues:any[] = [];//[20, 18, 16 , 16, 12, 12, 8, 8, 6, 5];

  public top10Risks:any[];

  public seriesLabels: any = {
    visible: true,
    padding: 3,
    font: 'bold 12px Arial, sans-serif',
    rotation: 'auto'
  };

  public selectedYear:any;
  public yearList:any[] = [];
  
  @HostBinding('attr.id') get get_id() { return 'dashboard'; }
  @HostBinding('class') get get_class() { return 'container-fluid'; }

  constructor(private intl: IntlService,private dashboardService:DashboardService,private dataService:DataService) {
    this.labelContent = this.labelContent.bind(this);
  }

  ngOnInit() {
    this.selectedYear = new Date().getFullYear();
    for(let year = 2022; year <= new Date().getFullYear(); year++){
      this.yearList.push(year);
    } 
  }

  ngAfterViewInit(): void {
    this.getDashboardCounts();
    this.getTop10Risks();
  }

  getDashboardCounts(){
    this.isBusy = true;
    this.dashboardService.getDashboardCounts(this.selectedYear).subscribe(data=>{
      if(null!=data && undefined!=data){
        this.surveysCount = data?.surveys;
        this.usersCount = data?.users;
        this.usersYetToTakeSurveyCount = data?.usersYetToTakeSurvey;
        this.risksCount = data?.risks;
      }
      this.isBusy = false;
    },error=>{
      this.isBusy = false;
    });
  }

  getTop10Risks(){
    this.isBusy = true;
    this.dataService.getRisksForDashboardAsList('top10',[0], this.selectedYear).subscribe(data=>{
      if(data){
        this.top10Risks = data;
      }
      this.isBusy = false;
    },error=>{
      this.top10RiskLabels=[];
      this.top10RiskValues=[];
      this.isBusy = false;
    });
  }


  // Pie Chart Label
  public labelContent(args: LegendLabelsContentArgs): string {
    return `${args.dataItem.category}(${this.intl.formatNumber((args.dataItem.value/100), 'p')})`;
  }

  // Donut Chart label
  public donutlabelContent(e: any): string {
    return e.category;
  }


}
