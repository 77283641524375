export const inspectionList = [
    {
        surveyId:1,
        createdBy:'Vinod Manoharan',
        surveyDate:'2021-06-17'
    },
    {
        surveyId:2,
        userName:'Anandraj Asokan',
        surveyDate:'2021-06-02'
    },
    {
        surveyId:3,
        userName:'Anandraj Asokan',
        surveyDate:'2021-06-15'
    },
    {
        surveyId:4,
        userName:'Anandraj Asokan',
        surveyDate:'2021-06-02'
    },
    {
        surveyId:5,
        createdBy:'Vinod Manoharan',
        surveyDate:'2021-06-02'
    },
    {
        surveyId:6,
        userName:'Anandraj Asokan',
        surveyDate:'2021-06-16'
    },
    {
        surveyId:7,
        createdBy:'Vinod Manoharan',
        surveyDate:'2021-06-02'
    },
    {
        surveyId:8,
        userName:'Anandraj Asokan',
        surveyDate:'2021-06-17'
    },
    {
        surveyId:9,
        userName:'Anandraj Asokan',
        surveyDate:'2021-06-02'
    },
    {
        surveyId:10,
        userName:'Anandraj Asokan',
        surveyDate:'2021-06-01'
    },
]

export const riskCompletenessList = [
    {
        riskCompId:1,
        createdBy:'Vinod Manoharan',
        riskTbIncluded:'Reputation',
        riskTbRemoved:'Vertical Integration',
        createdOn:'2021-06-17'
    },
    {
        riskCompId:2,
        createdBy:'Anandraj Asokan',
        riskTbIncluded:'Control Environment',
        riskTbRemoved:'Vertical Integration',
        createdOn:'2021-06-17'
    },
    {
        riskCompId:3,
        createdBy:'Anandraj Asokan',
        riskTbIncluded:'Labor Relations',
        riskTbRemoved:'Succession Planning',
        createdOn:'2021-06-17'
    },
]

export const riskIdentificationList = [
    {
        riskCompId:1,
        createdBy:'Vinod Manoharan',
        description:'Board Performance/Effectiveness',
        impact:'Minor',
        likelihood:'Possible',
        createdOn:'2021-06-17'
    },
    {
        riskCompId:2,
        createdBy:'Vinod Manoharan',
        description:'Capital Allocation',
        impact:'Moderate',
        likelihood:'Remote',
        createdOn:'2021-06-17'
    },
    {
        riskCompId:3,
        createdBy:'Anandraj Asokan',
        description:'Pension Funds',
        impact:'Significant',
        likelihood:'Possible',
        createdOn:'2021-06-17'
    },
    {
        riskCompId:4,
        createdBy:'Anandraj Asokan',
        description:'Data Protection',
        impact:'Moderate',
        likelihood:'Remote',
        createdOn:'2021-06-17'
    },
    {
        riskCompId:5,
        createdBy:'Vinod Manoharan',
        description:'Policy',
        impact:'Minor',
        likelihood:'Possible',
        createdOn:'2021-06-17'
    },
]