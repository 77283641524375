import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import {
  AppHeaderModule,
  AppSidebarModule,
  AppFooterModule,
} from '@coreui/angular';

import { AppComponent } from './app.component';
import { DefaultLayoutComponent, TutorialsDialog, HelpDialog } from './container';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CdkColumnDef } from '@angular/cdk/table';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { WidgetsModule } from './widgets/widgets.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Globals } from './globals/globals';
import { environment } from '../environments/environment';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';
import { MSAL_GUARD_CONFIG, InteractionType, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor } from './msal';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DatePipe } from '@angular/common';
import 'hammerjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './views/dashboard/dashboard/dashboard.component';
import { PageMessageComponent } from './widgets/page-message/page-message.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { SharedModule } from './shared/shared.module';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { InspectionListComponent } from './views/view/inspection-list/inspection-list.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      authority: 'https://login.microsoftonline.com/adient.onmicrosoft.com/',
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', ['user.read.all']);
  //protectedResourceMap.set(environment.apiUrl + '/', [environment.securityUrl]);

  return {
    // interactionType: InteractionType.POPUP,
    interactionType: InteractionType.REDIRECT,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    DashboardComponent,
    HomeComponent,
    LoginComponent,
    HelpDialog,
    TutorialsDialog,
    PageMessageComponent,
    InspectionListComponent
  ],
  imports: [
    MatStepperModule,
    WidgetsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    AppHeaderModule,
    PerfectScrollbarModule,
    AppSidebarModule,
    AppFooterModule,
    BrowserAnimationsModule,
    AppHeaderModule,
    MatMenuModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTableModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatIconModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    TabsModule,
    CollapseModule,
    NgbModule,
    ToolBarModule,
    DateInputsModule,
    ChartsModule,
    GridModule,
    PDFModule,
    ExcelModule,
    FontAwesomeModule,
    LayoutModule,
    DialogsModule,
    ButtonsModule,
    TreeListModule,
    SharedModule,
    LabelModule,
    TooltipModule,
    ListViewModule,
    NotificationModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports: [InspectionListComponent],
  entryComponents: [],
  providers: [
    CdkColumnDef,
    Globals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        // interactionType: InteractionType.POPUP
        interactionType: InteractionType.REDIRECT
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    DatePipe,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
