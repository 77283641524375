import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) {

  }

  generateOTP(email: string) {
    return this.httpClient.get<any>(environment.apiUrl + '/generateOTP/' + email);
  }

  validateOTP(email: string, otp: number) {
    return this.httpClient.get<any>(environment.apiUrl + '/validateOTP/' + email + '/' + otp);
  }
}
