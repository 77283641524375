import { Globals } from './globals/globals';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, InteractionType } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalService } from './msal';
import { ProfileService } from './services/profile.service';
import { enableMSAL } from './config/msal.config';
import { UserInfo } from './models/user-info.model';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  title = 'ProDash';

  isIframe = false;
  loggedIn = false;
  profile: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private profileInfo: ProfileService,
    private router: Router,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    if(enableMSAL){      
    this.isIframe = window !== window.parent && !window.opener;
    this.profileInfo.isUsrLoggedIn$.subscribe(
      data => {
        console.log('login subscriber in app component - is user already logged in', data);
        this.loggedIn = data;
        this.checkAccount();
      },
      error => {
        this.loggedIn = false;
        console.log('login subscriber in app component - user not logged in yet - error', error);
      }
    );
    this.profileInfo.usrInfoSubject$.subscribe(
      data => {
        console.log('profile subscriber in app component - user info updated', data);
        this.profile = data;
        this.globals.LoggedInUser = data;
      },
      error => {
        console.log('profile subscriber in app component - user info update error', error);
      }
    );
    this.checkAccount();
    }else{
      //Mock User Info
      this.profileInfo.updateUserInfo(this.mockUserInfo());
      this.globals.LoggedInUser = this.mockUserInfo();
    }
  }

  /**  */
  checkAccount() {
    console.log('check account called ');
    if (this.loggedIn || this.authService.getAllAccounts().length > 0) {
      this.loggedIn = true;
      this.profileInfo.getProfile();
      //this.router.navigate(['/prodash']);
    }
  }

  mockUserInfo():UserInfo{
      let userInfo = new UserInfo();
      userInfo.displayName = 'Anand';
      userInfo.givenName = 'Anandraj';
      userInfo.surname = 'Asokan';
      userInfo.jobTitle = 'Information Systems Consultant';
      userInfo.mail = 'aasokaan@adient.com';
      userInfo.mobilePhone = '+12483789327';
      userInfo.officeLocation = 'Plymouth';
      userInfo.globalId = 'aasokaan';
      return userInfo;
  }

  /** Logout */
  logout() {
    this.authService.logout();
  }

}
