
import { UserInfo } from '../models/user-info.model';
import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  public LoggedInUser:UserInfo;
  public user:any;
  public isAdmin:boolean;
  public roles:string[];
  public permissions:string[];
  public isAuthenticated:boolean;
}

