import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlaySpinnerComponent } from './overlay-spinner.component';

@NgModule({
  declarations: [
    OverlaySpinnerComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [

  ],
  entryComponents: [OverlaySpinnerComponent],
  exports: [
    OverlaySpinnerComponent
  ]
})
export class OverlaySpinnerModule { }
