import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { UploadFileModule } from './upload-file/upload-file.module';
import { SearchUserModule } from './search-user/search-user.module';
import { OverlaySpinnerModule } from './overlay-spinner/overlay-spinner.module';
import { PageMessageComponent } from './page-message/page-message.component';

//Generic Create
import { DynamicFieldDirective } from './generic-form/components/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './generic-form/components/dynamic-form/dynamic-form.component';;
import { InputComponent } from './generic-form/components/input/input.component';
import { ButtonComponent } from './generic-form/components/button/button.component';
import { SelectComponent } from './generic-form/components/select/select.component';
import { DateComponent } from './generic-form/components/date/date.component';
import { RadiobuttonComponent } from './generic-form/components/radiobutton/radiobutton.component';
import { CheckboxComponent } from './generic-form/components/checkbox/checkbox.component';
import { TextAreaComponent } from './generic-form/components/textarea/textarea.component';
import { KendoFileUploadComponent } from './generic-form/components/kendofileupload/kendofileupload.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MultiSelectComponent } from './generic-form/components/multiselect/multiselect.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
  declarations: [DynamicFieldDirective,DynamicFormComponent,InputComponent,ButtonComponent,SelectComponent,
    DateComponent,RadiobuttonComponent,CheckboxComponent,TextAreaComponent,MultiSelectComponent,KendoFileUploadComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    UploadFileModule,
    SearchUserModule,
    OverlaySpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    DialogsModule,
    ButtonsModule
  ],
  providers: [],
  exports: [
    MatDialogModule,
    UploadFileModule,
    SearchUserModule,
    OverlaySpinnerModule,
    DynamicFieldDirective,
    DynamicFormComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    TextAreaComponent,
    MultiSelectComponent,
    KendoFileUploadComponent
  ]
})
export class WidgetsModule {

}
